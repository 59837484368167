<template>
  <b-card no-body class="mb-1 rounded-0" :class="!item.statu && 'border-danger'">
    <b-card-header header-tag="header" class="p-3" role="tab" v-b-toggle="'accordion-' + index">
      <span>
        <span class="font-weight-bold pr-3"> {{ item.banka_hesap_turu }}</span>
        {{ item.banka_adi }}
      </span>
    </b-card-header>
    <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
      <b-card-body>
        <b-card-text>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
              <b-row>
                <b-col cols="12" md="6" class="pt-1">
                  <validation-provider name="Banka Adı" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Banka Adı">
                      <b-form-input
                        size="lg"
                        class="rounded-0"
                        ref="banka_adi"
                        v-model="item.banka_adi"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6">
                  <validation-provider name="Hesap Türü" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Hesap Türü" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                      <v-select
                        v-model="item.banka_hesap_turu"
                        :options="kurTurleri"
                        :reduce="(kur) => kur.banka_kodu"
                        label="banka_kodu"
                        :clearable="false"
                        :class="{ 'is-invalid': !!validationContext.errors[0] }"
                        class="select-size-lg"
                        :state="getValidationState(validationContext)"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <validation-provider name="Iban" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Iban">
                      <b-form-input
                        size="lg"
                        class="rounded-0"
                        ref="banka_iban"
                        v-mask="'TR## #### #### #### #### #### ##'"
                        v-model="item.banka_iban"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Banka Swift Kodu">
                    <b-form-input
                      size="lg"
                      class="rounded-0"
                      ref="banka_swift_kodu"
                      v-model="item.banka_swift_kodu"
                      aria-describedby="input-1-live-feedback"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Banka Kodu">
                    <b-form-input
                      size="lg"
                      class="rounded-0"
                      ref="banka_kodu"
                      v-model="item.banka_kodu"
                      aria-describedby="input-1-live-feedback"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Banka Adres">
                    <b-form-input
                      size="lg"
                      class="rounded-0"
                      ref="banka_adresi"
                      v-model="item.banka_adresi"
                      aria-describedby="input-1-live-feedback"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="d-flex justify-content-between align-items-center py-1">
                    <label class="font-weight-bold h6">Kullanım Durumu</label>
                    <b-form-checkbox :checked="item.statu" v-model="item.statu" switch size="lg" />
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6" lg="6">
                  <div class="d-flex">
                    <b-button squared block type="submit" size="lg" variant="warning" class="mr-2">
                      <i class="fad fa-save pr-2"></i>
                      <span class="align-middle">KAYDET</span>
                    </b-button>
                    <b-button squared size="lg" variant="danger" @click="handlerRemove(item.k_no)">
                      <i class="fad fa-trash"></i>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { defineComponent, toRefs } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    index: {
      type: Number,
      requried: true,
    },
    item: {
      type: Object,
      requried: true,
    },
    kurTurleri: {
      type: Array,
      requried: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { item } = toRefs(props);

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('bankaGuncelle', item.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Güncelleme Başarılı.', { position: 'bottom-left' });
            context.emit('show', false);
          }
        })
        .catch((err) => {
          context.emit('show', false);
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Firmaya Ait Tüm Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('bankaSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme başarılı.', { position: 'bottom-left' });
              context.emit('show', false);
            }
          });
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
