<template>
  <div class="p-3">
    <b-row>
      <b-col cols="12" v-if="!bankalar.length">
        <b-alert variant="warning" show class="text-center">
          <h4 class="alert-heading">Bilgi!</h4>
          <p>Henüz Kayıt Bulunmamaktadır.</p>
        </b-alert>
      </b-col>
      <b-col cols="12" v-else>
        <BankaItem
          v-for="(item, index) in bankalar"
          :key="index"
          :index="index"
          :item="item"
          :kurTurleri="kurTurleri"
          @show="$emit('show', $event)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6" lg="6">
        <div class="d-flex">
          <b-button squared block size="lg" variant="primary" class="mr-2" @click="onSubmit">
            <i class="fad fa-save pr-2"></i>
            YENİ EKLE
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store';
import BankaItem from './component/BankaItem.vue';
import { useToast } from 'vue-toastification/composition';
import { computed, defineComponent, ref } from '@vue/composition-api';
export default defineComponent({
  components: {
    BankaItem,
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.bankalar = computed(() => store.getters.getBankalar);
    expo.kurTurleri = computed(() => store.getters.getKurTurleri);

    expo.form = ref({
      banka_adi: 'Yeni Banka',
      banka_iban: 'TR',
      banka_swift_kodu: null,
      banka_kodu: null,
      banka_adresi: null,
      banka_hesap_turu: 'TRY',
      statu: true,
    });

    store.dispatch('kurTurleriListele');

    store.dispatch('bankaListele');

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('bankaEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Ekleme Başarılı.', { position: 'bottom-left' });
            context.emit('show', false);
          }
        })
        .catch((err) => {
          context.emit('show', false);
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
